import Image from 'next/image'


export default function PrivacyPolicyComponent() {
    return (
        <article className='sectionOrArticleTB'>
            <div className='text-center'>
                <h1 className='caregoriesTitle'>404 - This page is not found</h1>
            </div>
            <article className='sectionOrArticleTB'>
                <div className='imgStripContainer'>
                    <div className='imgStripText'>
                        <Image src='/images/Strip5.gif'
                            alt="Strip"
                            fill
                            sizes="(max-width: 768px) 100vw,
                        (max-width: 1200px) 50vw,
                        33vw"
                        />
                    </div>
                </div>
            </article>
        </article>
    )
}